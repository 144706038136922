<template>
  <div class="form-elements">
    <vuestic-widget>
      <div class="row">
        <div class="col-md-3">
          <button
            type="button"
            v-on:click="back"
            class="btn btn-primary btn-sm"
          >{{'view.typeevent.back' | translate}}</button>
        </div>
        <div class="col-md-3 offset-md-6">
          <!-- <button type="button" class="btn btn-warning btn-sm">Delete Element</button> -->
        </div>
      </div>
    </vuestic-widget>
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="'Nueva Zona de Acceso'">
          <p>La zona de acceso le permite asignarle a una membresia acceso a alguna zona del evento</p>
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-12">
                <fieldset>
                  <div class="row">
                    <div class="col-md-6">
                        <label>Membresia</label>
                      <multiselect
                        v-model="membership"
                        label="name"
                        placeholder="Selecciona un elemento"
                        :options="memberships"
                        :searchable="true"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          Membresia:
                          <label style="font-weight:600">{{ option.name }}</label>
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-md-6 with-icon-right">
                        <label>Zona de Evento</label>
                      <multiselect
                        v-model="zoneevent"
                        label="name"
                        placeholder="Selecciona un elemento"
                        :options="zoneevents"
                        :searchable="true"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          Zona de Evento:
                          <label style="font-weight:600">{{ option.name }}</label>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </fieldset>
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-4">
                    <label
                      class="control-label"
                      for="location"
                    >{{'view.station.fields.status' | translate}}</label>

                    <div
                      class="form-group with-icon-right"
                      :class="{'has-error': errors.has('active')}"
                    >
                      <div class="input-group">
                        <vuestic-switch v-model="active">
                          <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                          <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                        </vuestic-switch>
                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                        <i class="fa fa-check valid-icon icon-right input-icon"></i>
                        
                        <small
                          v-show="errors.has('active')"
                          class="help text-danger"
                        >{{ errors.first('active') }}</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 offset-md-12">
                  <button class="btn btn-primary btn-sm" type="submit">{{$t('view.typeevent.save')}}</button>
                </div>
              </div>
            </div>
          </form>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";

import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";
export default {
  name: "new-product-by-station",
  components: {
    SpringSpinner,
    Multiselect
  },
  data() {
    return {
      membership: "",
      memberships: [],
      zoneevent: "",
      zoneevents: [],
      active: 1
    };
  },
  mounted() {
    // checkbox and radios
    // console.log(this.idcompany);
    this.loadMembershipsByEvent(this.idevent)
      .then(data => {
        this.memberships = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });
    this.loadZoneeventByEvent(this.idevent)
      .then(data => {
        this.zoneevents = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });
  },
  computed: {
    ...mapState({
      //products: state => state.product.products,
      idevent: state => state.event.event.id,
      idcompany: state => state.auth.company.id
    })
  },
  methods: {
    ...mapActions([
      "storeZoneaccess",
      "loadZoneeventByEvent",
      "loadMembershipsByEvent",
      "addToastMessage"
    ]),
    onSubmit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.storeZoneaccess({           
            id_zone_event: this.zoneevent.id,
            id_membership: this.membership.id,
            active: this.active
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });
              setTimeout(() => this.$router.replace("/zoneaccess"), 2000);
            })
            .catch(data => {
              this.error = data.message;
            });
        }
      });
    },
    clear(field) {
      this[field] = "";
    },
    back() {
      this.$router.go(-1);
      // this.$router.push('/')
    }
  },
  computed: {
    ...mapState({
      idevent: state => state.event.event.id
    }),

    isSuccessfulNameValid() {
      let isValid = false;
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid;
      }
      return isValid;
    },
    isSuccessfulDescriptionValid() {
      let isValid = false;
      if (this.formFields.description) {
        isValid =
          this.formFields.description.validated &&
          this.formFields.description.valid;
      }
      return isValid;
    }
  }
};
</script>
